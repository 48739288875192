<template>
  <div class="logout"></div>
</template>

<script>
export default {
  name: "Logout",
  created() {
    this.$store
      .dispatch("userLogout")
      .then(() => this.$router.push({ name: "Home" }));
  },
};
</script>
